import React from 'react';
import './PostCard.css';

import { FaHeart } from 'react-icons/fa';
import { useHistory } from 'react-router';

const PostCard = ({ title, color, likes, isNew, postId, isDraft, type }) => {
	const history = useHistory();

	const handleCardClick = () => {
		if (isDraft) {
			history.push(`/viewDraft?id=${postId}`);
		} else {
			history.push(`/viewPost?id=${postId}`);
		}
	};

	if (type === 'magazine') {
		var magazineStyle = {
			backgroundImage: `url(${color})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		};

		var magazineTitleStyle = {
			borderRadius: '6px',
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.4)',
		};
	}

	return (
		<>
			{type === 'post' ? (
				<div
					className='card'
					style={{ backgroundColor: color }}
					// rohan edit
					onClick={handleCardClick}
				>
					<p className='card-title'>{title}</p>
					{isNew ? <div className='new'>New</div> : null}
					<div className='likes'>
						<p>{likes !== 0 ? likes : 'No Likes'}</p> <FaHeart />
					</div>
				</div>
			) : (
				<div
					className='card'
					style={magazineStyle}
					onClick={handleCardClick}
				>
					{isNew ? <div className='new'>New</div> : null}
					<div className='likes'>
						<p>{likes !== 0 ? likes : 'No Likes'}</p> <FaHeart />
					</div>
				</div>
			)}
		</>
	);
};
/**This is card title
 * <p className='card-title' style={magazineTitleStyle}>
						{title}
					</p>
 */
export default PostCard;
