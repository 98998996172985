import React, { useState } from 'react';
import './NewMagazine.css';

import Navbar from '../Navbar/Navbar';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dialog from '@material-ui/core/Dialog';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { v4 as uuid } from 'uuid';
import { Firebase } from '../../Firebase';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

function NewMagazine() {
	const history = useHistory();
	const storage = Firebase.storage();
	const db = Firebase.firestore();
	const user = JSON.parse(localStorage.getItem('user'));

	const [open, setOpen] = useState(false);
	const [colorDialogOpen, setColorDialogOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [backgroundImage, setBackgroundImage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [image, setImage] = useState(null);

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	const [file, setFile] = useState(null);
	const [magazine, setMagazine] = useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onImageChange = (e) => {
		setImage(e.target.files[0]);
	};

	const onChange = (e) => {
		setFile(e.target.files[0]);
	};

	const selectFile = () => {
		if (file) {
			setMagazine(file);
		}
		handleClose();
	};

	const goToPrevPage = () => {
		if (pageNumber !== 1) {
			setPageNumber(pageNumber - 1);
		}
	};

	const goToNextPage = () => {
		setPageNumber(pageNumber + 1);
	};

	const handleImageUpload = () => {
		setIsLoading(true);
		const id = uuid();
		let filename = image.name;
		const ext = filename.split('.')[filename.split('.').length - 1];
		filename = `${id}.${ext}`;
		const uploadTask = storage.ref(`/posts/${filename}`).put(image);
		uploadTask.on(
			'state_changed',
			(snapshot) => {},
			// Error func
			(err) => {
				console.log(err);
			},
			// Push to db after image uploads
			() => {
				storage
					.ref('posts')
					.child(filename)
					.getDownloadURL()
					.then((url) => {
						handleMagazinePublish(url);
					});
			}
		);
	};

	const handleMagazinePublish = async (backgroundImage) => {
		if (magazine === null || title === '' || backgroundImage === '') {
			return;
		}

		const id = uuid();

		let filename = magazine.name;
		const ext = filename.split('.')[filename.split('.').length - 1];
		filename = `${id}.${ext}`;

		const uploadTask = storage.ref(`/posts/${filename}`).put(magazine);

		uploadTask.on(
			'state_changed',
			(snapshot) => {},
			(err) => {
				console.log(err);
			},
			() => {
				storage
					.ref('posts')
					.child(filename)
					.getDownloadURL()
					.then((url) => {
						let post = {
							timestamp: JSON.stringify(new Date()),
							title: title,
							body: JSON.stringify(url),
							uid: user.uid,
							username: user.username,
							userImage: user.imageUrl,
							backgroundColor: backgroundImage,
							type: 'magazine',
							likes: [],
							comments: [],
						};

						db.collection('/posts')
							.add(post)
							.then((_) => {
								setTitle('');
								setImage(null);
								setBackgroundImage('');
								setIsLoading(false);
							})
							.then((_) => {
								history.replace('/');
							});
					});
			}
		);
	};

	return (
		<div className='home-container'>
			<div className='newMagazine'>
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							width: '300px',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress color='secondary' />
						Publishing...
					</div>
				) : (
					<>
						<div
							className='newMagazine__add'
							onClick={handleClickOpen}
						>
							<AddBoxIcon />
							<h2>Add a magazine</h2>
						</div>

						<Dialog open={open} onClose={handleClose}>
							<div className='newMagazine__dialog'>
								<h2>Select your pdf</h2>
								<input
									type='file'
									name='upload'
									accept='application/pdf'
									onChange={onChange}
								/>

								<div className='newMagazine__dialogButtons'>
									<Button
										variant='contained'
										color='primary'
										onClick={handleClose}
									>
										Cancel
									</Button>
									<Button
										variant='contained'
										color='primary'
										onClick={selectFile}
									>
										OK
									</Button>
								</div>
							</div>
						</Dialog>

						<Dialog
							open={colorDialogOpen}
							onClose={() => setColorDialogOpen(false)}
						>
							<div className='dialogBoxColor'>
								<input
									style={{
										width: '80%',
										fontSize: '1rem',
										padding: '0.5rem',
									}}
									placeholder='Enter Title'
									type='text'
									onChange={(e) => setTitle(e.target.value)}
									value={title}
								/>
								<h2>Select Cover Image</h2>
								<div className='coverColors'>
									{image ? (
										<img
											src={URL.createObjectURL(image)}
											alt='cover'
										/>
									) : (
										<input
											type='file'
											onChange={onImageChange}
										/>
									)}
								</div>
								<Button
									variant='contained'
									color='primary'
									startIcon={<PublishIcon />}
									onClick={handleImageUpload}
								>
									Publish
								</Button>
							</div>
						</Dialog>

						{magazine && (
							<div className='newMagazine__preview'>
								<div className='newMagazine__previewButtons'>
									<Button
										variant='contained'
										color='primary'
										startIcon={<ChevronLeftIcon />}
										onClick={goToPrevPage}
									></Button>
									<Button
										variant='contained'
										color='primary'
										startIcon={<ChevronRightIcon />}
										onClick={goToNextPage}
									></Button>
								</div>

								<>
									<Document
										file={magazine}
										onLoadSuccess={onDocumentLoadSuccess}
									>
										<Page
											pageNumber={pageNumber}
											width={600}
										/>
									</Document>
									<p>
										Page {pageNumber} of {numPages}
									</p>
								</>

								<Button
									variant='contained'
									color='primary'
									startIcon={<PublishIcon />}
									onClick={() => setColorDialogOpen(true)}
								>
									Create
								</Button>
							</div>
						)}
					</>
				)}
			</div>

			<Navbar />
		</div>
	);
}

export default NewMagazine;
