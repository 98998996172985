import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',

  initialState: {
    profileNav: null,

  },

  reducers: {
    activeProfileNav: (state, action) => {
      state.profileNav = action.payload;
    },

  },
});

export const { activeProfileNav } = appSlice.actions;

export const selectProfileNav = state => state.app.profileNav;

export default appSlice.reducer;
