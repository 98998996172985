import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Firebase } from '../../Firebase';
import { useHistory } from 'react-router';
import '../ViewPost/ViewPost.css';

import Navbar from '../Navbar/Navbar';

import { Avatar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPost(props) {
	const history = useHistory();

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [magazine, setMagazine] = useState(null);
	const [type, setType] = useState('');
	const [post, setPost] = useState({});

	const db = Firebase.firestore();
	const search = props.location.search;
	const postId = new URLSearchParams(search).get('id');
	const user = JSON.parse(localStorage.getItem('user'));

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () => {
		if (pageNumber !== 1) {
			setPageNumber(pageNumber - 1);
		}
	};

	const goToNextPage = () => {
		setPageNumber(pageNumber + 1);
	};

	useEffect(() => {
		var tag_id = document.getElementById('post-body');
		db.doc(`/drafts/${postId}`)
			.get()
			.then((doc) => {
				let postData = {
					id: postId,
					title: doc.data()['title'],
					userImage: doc.data()['userImage'],
					body: JSON.parse(doc.data()['body']),
					timestamp: JSON.parse(doc.data()['timestamp']),
					backgroundColor: doc.data()['backgroundColor'],
					uid: doc.data()['uid'],
					username: doc.data()['username'],
					likes: doc.data()['likes'],
					comments: doc.data()['comments'],
					type: doc.data()['type'],
				};

				if (postData.type === 'magazine') {
					setMagazine(postData.body);
				} else {
					tag_id.innerHTML = postData.body;
				}
				setType(postData.type);
				setPost(postData);
			});
	}, []);

	const handlePublish = async () => {
		let newPost = {
			title: post.title,
			userImage: post.userImage,
			body: JSON.stringify(post.body),
			timestamp: JSON.stringify(post.timestamp),
			backgroundColor: post.backgroundColor,
			uid: post.uid,
			username: post.username,
			likes: post.likes,
			comments: post.comments,
			type: post.type,
		};

		await db
			.collection('/posts')
			.add(newPost)
			.then((_) => {
				handleDelete();
			});
	};

	const handleDelete = async () => {
		await db
			.doc(`/drafts/${postId}`)
			.delete()
			.then((_) => {
				history.replace('/');
			});
	};

	return (
		<div className='home-container'>
			<div className='viewPost'>
				<div className='viewPost__header'>
					{post === {} ? (
						<p>No Post Found</p>
					) : (
						<>
							<p>{post.title}</p>
							<div
								className='viewPost__headerInfo'
								onClick={() =>
									history.push(`/profile/?id=${post.uid}`)
								}
							>
								<Avatar
									src={post.userImage}
									alt='user-avatar'
								/>

								<p>{post.username}</p>
							</div>
						</>
					)}
				</div>

				{type === 'magazine' ? (
					<div className='newMagazine__preview'>
						<div className='newMagazine__previewButtons'>
							<Button
								variant='contained'
								color='primary'
								startIcon={<ChevronLeftIcon />}
								onClick={goToPrevPage}
							></Button>
							<Button
								variant='contained'
								color='primary'
								startIcon={<ChevronRightIcon />}
								onClick={goToNextPage}
							></Button>
						</div>

						<>
							<Document
								file={magazine}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page
									pageNumber={pageNumber}
									width={isMobileOnly ? 350 : 600}
								/>
							</Document>
							<p>
								Page {pageNumber} of {numPages}
							</p>
						</>
					</div>
				) : (
					<div className='viewPost__body'>
						{/* {post.imageUrl && (
							<img
								src={post.imageUrl}
								alt='post cover'
								style={{
									height: '200px',
									width: '50%',
									objectFit: 'contain',
								}}
							/>
						)} */}

						{post === {} ? (
							<p>No Post Found</p>
						) : (
							<div id='post-body' className='editor'></div>
						)}
					</div>
				)}

				<div className='draft-buttons'>
					<Button
						variant='contained'
						color='secondary'
						onClick={handlePublish}
					>
						Publish
					</Button>

					<div className='delete-button'>
						<Button
							variant='contained'
							className='deleteButton'
							startIcon={<DeleteIcon />}
							onClick={handleDelete}
						>
							Delete
						</Button>
					</div>
				</div>
			</div>

			<Navbar />
		</div>
	);
}

export default ViewPost;
