import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './NewPost.css';

import LinearProgress from '@material-ui/core/LinearProgress';

import Navbar from '../Navbar/Navbar';
import RTE from './RTE';

const Newpost = () => {
	const history = useHistory();

	const [title, setTitle] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			history.push('/signin');
		}
	}, []);

	return (
		<div className='home-container'>
			<div className='newPost'>
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							width: '300px',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<LinearProgress color='secondary' />
						Publishing...
					</div>
				) : (
					<>
						<div className='newPost__Header'>
							<input
								placeholder='TITLE'
								value={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
						</div>

						<RTE title={title} setIsLoading={setIsLoading} />
					</>
				)}
			</div>
			<Navbar />
		</div>
	);
};

export default Newpost;
