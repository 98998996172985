import React from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Components/Home/Home';
import Signin from './Components/Signin/Signin';
import Discover from './Components/Discover/Discover';
import Profile from './Components/Profile/Profile';
import NewPost from './Components/NewPost/Newpost';
import ViewPost from './Components/ViewPost/ViewPost';
import NewMagazine from './Components/NewMagazine/NewMagazine';
import Drafts from './Components/Drafts/Drafts';
import ViewDraft from './Components/ViewDraft/ViewDraft';
import About from './Components/About/About';
import Support from './Components/Support/Support';
import Terms from './Components/Terms/Terms';

function App() {
	return (
		<div>
			<Router>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/signin' component={Signin} />
					<Route exact path='/discover' component={Discover} />
					<Route path='/profile' component={Profile} />
					<Route exact path='/addNewPost'>
						<NewPost />
					</Route>
					<Route exact path='/magazine'>
						<NewMagazine />
					</Route>
					<Route path='/viewPost' component={ViewPost} />
					<Route exact path='/drafts' component={Drafts} />
					<Route path='/viewDraft' component={ViewDraft} />
					<Route path='/about' component={About} />
					<Route path='/support' component={Support} />
					<Route path='/terms' component={Terms} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
