import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Firebase } from '../../Firebase';
import { useHistory } from 'react-router-dom';
import './ViewPost.css';

import Navbar from '../Navbar/Navbar';

import { Avatar } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import moment from 'moment';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ViewPost(props) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [magazine, setMagazine] = useState(null);
	const [type, setType] = useState('');
	const [post, setPost] = useState({});
	const [like, setLike] = useState(false);
	const [likeCount, setLikeCount] = useState(0);
	const [commentCount, setCommentCount] = useState(0);
	const [comment, setComment] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [comments, setComments] = useState([]);

	const history = useHistory();
	const db = Firebase.firestore();
	const search = props.location.search;
	const postId = new URLSearchParams(search).get('id');
	const user = JSON.parse(localStorage.getItem('user'));

	const toggleLike = async () => {
		await db
			.doc(`/posts/${postId}`)
			.get()
			.then((doc) => {
				let likeArray = doc.data()['likes'];
				if (like) {
					likeArray = likeArray.filter((id) => id !== user.uid);
				} else {
					likeArray.push(user.uid);
				}
				db.doc(`/posts/${postId}`).update({ likes: likeArray });
			})
			.then((_) => {
				if (like) {
					setLikeCount(likeCount - 1);
					setLike(false);
				} else {
					setLikeCount(likeCount + 1);
					setLike(true);
				}
			});
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		} else {
			setPageNumber(numPages);
		}
	};

	const goToNextPage = () => {
		if (pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		} else {
			setPageNumber(1);
		}
	};

	const handleCommentChange = (e) => {
		e.preventDefault();
		setComment(e.target.value);
	};

	const addComment = async () => {
		if (comment.trim() === '') {
			return;
		}

		setIsLoading(true);

		let commentData = {
			username: user.username,
			uid: user.uid,
			body: comment.trim(),
			userImage: user.imageUrl,
		};

		await db
			.doc(`/posts/${postId}`)
			.get()
			.then((doc) => {
				let commentsArray = doc.data()['comments'];
				commentsArray.push(commentData);

				db.doc(`/posts/${postId}`).update({ comments: commentsArray });
			})
			.then((_) => {
				let stateComments = [...comments, commentData];
				setComments(stateComments);
				setCommentCount(commentCount + 1);
				setComment('');
				setIsLoading(false);
			});
	};

	const handleDelete = async () => {
		await db
			.doc(`/posts/${postId}`)
			.delete()
			.then((_) => {
				history.replace('/');
			});
	};

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			history.replace('/signin');
		} else {
			var tag_id = document.getElementById('post-body');
			db.doc(`/posts/${postId}`)
				.get()
				.then((doc) => {
					let postData = {
						id: postId,
						title: doc.data()['title'],
						userImage: doc.data()['userImage'],
						body: JSON.parse(doc.data()['body']),
						imageUrl: doc.data()['imageUrl'],
						timestamp: JSON.parse(doc.data()['timestamp']),
						backgroundColor: doc.data()['backgroundColor'],
						uid: doc.data()['uid'],
						username: doc.data()['username'],
						likes: doc.data()['likes'],
						comments: doc.data()['comments'],
						type: doc.data()['type'],
					};

					if (postData.type === 'magazine') {
						setMagazine(postData.body);
					} else {
						tag_id.innerHTML = postData.body;
					}
					setType(postData.type);
					setLikeCount(postData.likes.length);
					setLike(postData.likes.includes(user.uid));
					setCommentCount(postData.comments.length);
					setComments(postData.comments);
					setPost(postData);
				});
		}
	}, []);

	return (
		<div className='home-container'>
			<div className='viewPost'>
				<div className='viewPost__header'>
					{post === {} ? (
						<p>No Post Found</p>
					) : (
						<>
							{moment(post.timestamp).fromNow()}
							<div
								className='viewPost__headerInfo'
								onClick={() =>
									history.push(`/profile/?id=${post.uid}`)
								}
							>
								<Avatar
									src={post.userImage}
									alt='user-avatar'
								/>

								<p>{post.username}</p>
							</div>
						</>
					)}
				</div>
				<h2>{post?.title}</h2>

				{type === 'magazine' ? (
					<div className='newMagazine__preview'>
						<div className='newMagazine__previewButtons'>
							<Button
								variant='contained'
								color='primary'
								startIcon={<ChevronLeftIcon />}
								onClick={goToPrevPage}
							></Button>
							<Button
								variant='contained'
								color='primary'
								startIcon={<ChevronRightIcon />}
								onClick={goToNextPage}
							></Button>
						</div>

						<div style={{ display: 'grid', placeItems: 'center' }}>
							<Document
								file={magazine}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page
									pageNumber={pageNumber}
									width={isMobileOnly ? 350 : 600}
								/>
							</Document>
							<p>
								Page {pageNumber} of {numPages}
							</p>
						</div>
					</div>
				) : (
					<div className='viewPost__body'>
						{/* {post.imageUrl && (
							<img
								src={post.imageUrl}
								alt='post cover'
								style={{
									height: '200px',
									width: '50%',
									objectFit: 'contain',
								}}
							/>
						)} */}

						{post === {} ? (
							<p>No Post Found</p>
						) : (
							<div id='post-body' className='editor'></div>
						)}
					</div>
				)}

				<div className='viewPost__footer'>
					<div className='viewPost__footerIcons'>
						<div className='viewPost__footerIcon'>
							{like ? (
								<IconButton
									color='secondary'
									onClick={toggleLike}
								>
									<FavoriteIcon fontSize='small' />
									<p style={{ fontSize: '1.1rem' }}>
										{likeCount !== 0
											? likeCount
											: 'No Likes Yet'}
									</p>
								</IconButton>
							) : (
								<IconButton
									color='secondary'
									onClick={toggleLike}
								>
									<FavoriteBorderIcon fontSize='small' />
									<p style={{ fontSize: '1.1rem' }}>
										{likeCount !== 0
											? likeCount
											: 'No Likes'}
									</p>
								</IconButton>
							)}
						</div>

						<div className='viewPost__footerIcon'>
							<IconButton color='primary'>
								<ChatBubbleIcon fontSize='small' />
								<p style={{ fontSize: '1.1rem' }}>
									{commentCount !== 0
										? commentCount
										: 'No Reviews'}
								</p>
							</IconButton>
						</div>

						{post.uid === user?.uid ? (
							<div className='delete-button'>
								<Button
									variant='contained'
									className='deleteButton'
									startIcon={<DeleteIcon />}
									onClick={handleDelete}
								>
									Delete
								</Button>
							</div>
						) : null}
					</div>

					<div className='viewPost__footerNewComment'>
						<input
							placeholder='Add Review...'
							value={comment}
							onChange={(e) => handleCommentChange(e)}
						/>
						<Button
							onClick={addComment}
							variant='contained'
							color='primary'
						>
							{isLoading ? <CircularProgress /> : 'Add Review'}
						</Button>
					</div>

					{comments.length > 0
						? comments.map((comment, index) => (
								<div className='viewPost__comments' key={index}>
									<div className='viewPost__footerComments'>
										<div className='viewPost__footerCommentsAvatar'>
											<Avatar
												src={comment.userImage}
												alt='user'
											/>
											<h3>{comment.username}</h3>
										</div>

										<p>{comment.body}</p>
									</div>
								</div>
						  ))
						: null}
				</div>
			</div>

			<Navbar />
		</div>
	);
}

export default ViewPost;
