const firebaseConfig = {
  apiKey: "AIzaSyAbabFE6-AKERGQsFXtDJ5MnHKwthzkRCw",
  authDomain: "penpoem-c5ffa.firebaseapp.com",
  projectId: "penpoem-c5ffa",
  storageBucket: "penpoem-c5ffa.appspot.com",
  messagingSenderId: "187234572514",
  appId: "1:187234572514:web:7b6bdae34f57a578b1a6bb"
};

export default firebaseConfig;
