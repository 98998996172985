import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import '../Home/Home.css';

import Navbar from '../Navbar/Navbar';
import PostCard from '../PostCard/PostCard';

import { Firebase } from '../../Firebase';
import { CircularProgress, Avatar } from '@material-ui/core';

import Logo from '../../images/penpoem-logo.png';

const Drafts = () => {
	const db = Firebase.firestore();
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const history = useHistory();
	const user = JSON.parse(localStorage.getItem('user'));

	const fetchPosts = async () => {
		setIsLoading(true);
		await db
			.collection('/drafts')
			.where('uid', '==', `${user.uid}`)
			.orderBy('timestamp', 'desc')
			.get()
			.then((data) => {
				let postsData = [];
				data.docs.forEach((doc) => {
					let post = {
						id: doc.id,
						title: doc.data()['title'],
						userImage: doc.data()['userImage'],
						body: JSON.parse(doc.data()['body']),
						timestamp: JSON.parse(doc.data()['timestamp']),
						backgroundColor: doc.data()['backgroundColor'],
						uid: doc.data()['uid'],
						username: doc.data()['username'],
						likes: doc.data()['likes'],
						comments: doc.data()['comments'],
						type: doc.data()['type'],
					};

					const postDate = moment(post.timestamp);
					const now = moment(new Date());

					const duration = moment
						.duration(now.diff(postDate))
						.asDays();

					post.isNew = duration > 1 ? false : true;
					postsData.push(post);
				});
				setPosts(postsData);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			history.push('/signin');
		} else {
			fetchPosts();
		}
	}, []);

	return (
		<div className='home-container'>
			<div className='home'>
				<div className='home-header'>
					<div className='logo'>
						<img src={Logo} alt='logo' />
						<p>penpoem</p>
					</div>
				</div>
				<div className='draft-header'>
					<p>Drafts</p>

					<div className='viewPost__headerInfo'>
						<Avatar src={user.imageUrl} alt='user-avatar' />

						<p>{user.username}</p>
					</div>
				</div>
				<div className='cards-container'>
					{isLoading ? (
						<>
							<CircularProgress color='secondary' />
							<p>Loading...</p>
						</>
					) : posts.length > 0 ? (
						posts.map((post) => (
							<PostCard
								key={post.id}
								title={post.title}
								color={post.backgroundColor}
								isNew={post.isNew}
								likes={post.likes.length}
								postId={post.id}
								isDraft={true}
								type={post.type}
							/>
						))
					) : (
						<p>No Drafts Yet</p>
					)}
				</div>
				<Navbar />
			</div>
		</div>
	);
};

export default Drafts;
