import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './Home.css';

import Navbar from '../Navbar/Navbar';
import PostCard from '../PostCard/PostCard';

import { Firebase } from '../../Firebase';
import { Avatar, CircularProgress, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useHistory } from 'react-router';

import Logo from '../../images/penpoem-logo.png';

const Home = () => {
	const history = useHistory();

	const db = Firebase.firestore();
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [users, setUsers] = useState([]);

	const [filteredSearch, setFilteredSearch] = useState(null);

	const fetchUsers = async () => {
		await db
			.collection('/users')
			.get()
			.then((data) => {
				let userList = [];
				data.docs.forEach((doc) => {
					let user = {
						username: doc.data()['username'],
						imageUrl: doc.data()['imageUrl'],
						uid: doc.id,
					};
					userList.push(user);
				});
				setUsers(userList);
			});
	};

	const fetchPosts = async () => {
		setIsLoading(true);
		await db
			.collection('/posts')
			.orderBy('timestamp', 'desc')
			.where('type', '==', 'magazine')
			.get()
			.then((data) => {
				let postsData = [];
				data.docs.forEach((doc) => {
					let post = {
						id: doc.id,
						title: doc.data()['title'],
						userImage: doc.data()['userImage'],
						body: JSON.parse(doc.data()['body']),
						timestamp: JSON.parse(doc.data()['timestamp']),
						backgroundColor: doc.data()['backgroundColor'],
						uid: doc.data()['uid'],
						username: doc.data()['username'],
						likes: doc.data()['likes'],
						comments: doc.data()['comments'],
						type: doc.data()['type'],
					};

					const postDate = moment(post.timestamp);
					const now = moment(new Date());

					const duration = moment
						.duration(now.diff(postDate))
						.asDays();

					post.isNew = duration > 1 ? false : true;

					postsData.push(post);
				});
				setPosts(postsData);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fetchPosts();
		fetchUsers();
	}, []);

	const handleSearch = async () => {
		if (searchText.trim() === '') {
			return;
		}
	};

	useEffect(() => {
		const namesSearch = users.filter((user) =>
			user.username.toLowerCase().includes(searchText.toLowerCase())
		);
		setFilteredSearch(namesSearch);

		if (!searchText) {
			setFilteredSearch(null);
		}
	}, [searchText]);

	return (
		<div className='home-container'>
			<div className='home'>
				<div className='home-header'>
					<div className='logo'>
						<img src={Logo} alt='logo' />
						<p>penpoem</p>
					</div>

					<div className='search'>
						<input
							type='text'
							placeholder='Search...'
							onChange={(e) => setSearchText(e.target.value)}
							value={searchText}
						/>

						<Search className='search-logo' />
					</div>

					{filteredSearch && (
						<div className='searchResults'>
							{filteredSearch?.map((user) => (
								<div
									key={user.uid}
									className='searchResult'
									onClick={() =>
										history.push(`/profile/?id=${user.uid}`)
									}
								>
									<Avatar src={user.imageUrl} alt='' />
									<p>{user.username}</p>
								</div>
							))}
						</div>
					)}
				</div>
				<p className='bodyTitle'> </p>
				<div className='cards-container'>
					{isLoading ? (
						<>
							<CircularProgress color='secondary' />
							<p>Loading...</p>
						</>
					) : posts.length > 0 ? (
						posts.map((post) => (
							<PostCard
								key={post.id}
								title={post.title}
								color={post.backgroundColor}
								isNew={post.isNew}
								likes={post.likes.length}
								postId={post.id}
								type={post.type}
								isDraft={false}
							/>
						))
					) : (
						<p>No Posts Yet</p>
					)}
				</div>

				<Navbar />
			</div>
		</div>
	);
};

export default Home;
//line 144
//<p className='bodyTitle'>Magazines </p>
