import React from 'react';
import './Navbar.css';

import { NavLink } from 'react-router-dom';
import {
	MdInfo,
	MdAddCircle,
	MdExplore,
	MdAccountCircle,
	MdBook,
} from 'react-icons/md';

const Navbar = () => {
	const user = JSON.parse(localStorage.getItem('user'));

	return (
		<div className='navbar'>
			<NavLink exact to='/' className='link' activeClassName='active'>
				<MdBook fontSize='26px' />
				Magazines
			</NavLink>
			<NavLink
				exact
				to='/discover'
				className='link'
				activeClassName='active'
			>
				<MdExplore fontSize='26px' />
				Articles
			</NavLink>
			<NavLink
				exact
				to='/addNewPost'
				className='link'
				activeClassName='active'
			>
				<MdAddCircle fontSize='26px' />
				Add
			</NavLink>
			<NavLink
				exact
				to='/about'
				className='link'
				activeClassName='active'
			>
				<MdInfo fontSize='26px' />
				About
			</NavLink>
			<NavLink
				to={{
					pathname: '/profile',
					search: `?id=${user?.uid}`,
				}}
				className='link'
				activeClassName='active'
			>
				<MdAccountCircle fontSize='26px' />
				Profile
			</NavLink>
		</div>
	);
};

export default Navbar;
