import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';

import { Firebase } from '../../Firebase';
import Navbar from '../Navbar/Navbar';
import PostCard from '../PostCard/PostCard';

import Avatar from '@material-ui/core/Avatar';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { CircularProgress } from '@material-ui/core';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';

import './Profile.css';

const Profile = (props) => {
	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingUser, setIsLoadingUser] = useState(true);
	const [open, setOpen] = useState(false);
	const [userData, setUserData] = useState({});
	const [isFollowing, setIsFollowing] = useState(false);
	const [followerCount, setFollowerCount] = useState(null);
	const [followers, setFollowers] = useState([]);
	const [following, setFollowing] = useState([]);

	const [openF, setOpenF] = useState(false);

	const [show, setShow] = useState('');

	const search = props.location.search;
	const userId = new URLSearchParams(search).get('id');

	const db = Firebase.firestore();
	const user = JSON.parse(localStorage.getItem('user'));
	const history = useHistory();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenFollower = () => {
		setShow('Followers');
		setOpenF(true);
	};

	const handleClickOpenFollowing = () => {
		setShow('Following');
		setOpenF(true);
	};

	const handleCloseF = () => {
		setOpenF(false);
	};

	const findIsFollowing = (followers, uid) => {
		var i;
		for (i = 0; i < followers.length; i++) {
			if (followers[i]['id'] === uid) {
				return true;
			}
		}

		return false;
	};

	const fetchPosts = async () => {
		setIsLoading(true);
		let uid;

		if (user.uid !== userId) {
			uid = userId;
		} else {
			uid = user.uid;
		}

		await db
			.doc(`/users/${uid}`)
			.get()
			.then((doc) => {
				setUserData(doc.data());
				setFollowers(doc.data()['followers']);
				setFollowing(doc.data()['following']);
				setFollowerCount(doc.data()['followers'].length);
				setIsFollowing(
					findIsFollowing(doc.data()['followers'], user.uid)
				);
			});

		await db
			.collection('/posts')
			.where('uid', '==', `${uid}`)
			.orderBy('timestamp', 'desc')
			.get()
			.then((data) => {
				let postsData = [];
				data.docs.forEach((doc) => {
					let post = {
						id: doc.id,
						title: doc.data()['title'],
						userImage: doc.data()['userImage'],
						body: JSON.parse(doc.data()['body']),
						timestamp: JSON.parse(doc.data()['timestamp']),
						backgroundColor: doc.data()['backgroundColor'],
						uid: doc.data()['uid'],
						username: doc.data()['username'],
						likes: doc.data()['likes'],
						comments: doc.data()['comments'],
						type: doc.data()['type'],
					};
					const postDate = moment(post.timestamp);
					const now = moment(new Date());

					const duration = moment
						.duration(now.diff(postDate))
						.asDays();

					post.isNew = duration > 1 ? false : true;
					postsData.push(post);
				});
				setPosts(postsData);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		setIsLoadingUser(true);
		const user = JSON.parse(localStorage.getItem('user'));
		if (user === null) {
			history.push('/signin');
		} else {
			fetchPosts();
			setIsLoadingUser(false);
		}
	}, [userId]);

	// console.log(followers);
	// console.log(following);

	const toggleFollow = async () => {
		await db
			.doc(`/users/${userId}`)
			.get()
			.then((doc) => {
				let followers = doc.data()['followers'];
				if (isFollowing) {
					followers = followers.filter(
						(follower) => follower.id !== user.uid
					);
				} else {
					let newFollower = {
						id: user.uid,
						username: user.username,
					};
					followers.push(newFollower);
				}
				db.doc(`/users/${userId}`).update({ followers: followers });
			})
			.then(async (_) => {
				await db
					.doc(`/users/${user.uid}`)
					.get()
					.then((doc) => {
						let following = doc.data()['following'];
						if (isFollowing) {
							following = following.filter(
								(followingUser) => followingUser.id !== userId
							);
						} else {
							let newFollowing = {
								id: userId,
								username: userData.username,
							};
							following.push(newFollowing);
						}
						db.doc(`/users/${user.uid}`).update({
							following: following,
						});
					});
			})
			.then((_) => {
				if (isFollowing) {
					setFollowerCount(followerCount - 1);
					setIsFollowing(false);
				} else {
					setFollowerCount(followerCount + 1);
					setIsFollowing(true);
				}
			});
	};

	return (
		<div className='profile-container'>
			{isLoadingUser ? (
				<CircularProgress color='secondary' />
			) : (
				<div className='profile'>
					<div className='profile__header'>
						<div className='profile__headerTop'>
							<div className='profile__headerTopUser'>
								<Avatar
									className='profile__avatar'
									src={userData.imageUrl}
									alt='user-avatar'
								/>
								<p>{userData.username}</p>
							</div>
							{userId === user.uid ? (
								<div className='profile__headerRight'>
									<SettingsIcon onClick={handleClickOpen} />
								</div>
							) : null}
						</div>

						<div className='profile__headerBottom'>
							<div className='profile__headerBottomInfo'>
								<p
									style={{ cursor: 'pointer' }}
									onClick={handleClickOpenFollower}
								>
									CONNECTED BY{' '}
									{followerCount ? followerCount : '0'}
								</p>
								<p
									style={{ cursor: 'pointer' }}
									onClick={handleClickOpenFollowing}
								>
									CONNECTIONS{' '}
									{userData.following
										? userData.following.length
										: '0'}
								</p>
							</div>

							<Dialog id='ff' open={openF} onClose={handleCloseF}>
								<div className='profileDialog'>
									<div className='newD'>
										<p>
											{show === 'Followers'
												? 'Connected By'
												: 'Connections'}
										</p>

										<CloseIcon onClick={handleCloseF} />
									</div>

									{show === 'Followers' ? (
										<div className='dList'>
											{followers?.map((follower) => (
												<p
													key={follower.id}
													onClick={() =>
														history.push(
															`/profile/?id=${follower.id}`
														)
													}
												>
													{follower.username}
												</p>
											))}
										</div>
									) : (
										<div className='dList'>
											{following?.map((following) => (
												<p key={following.id}>
													{following.username}
												</p>
											))}
										</div>
									)}
								</div>
							</Dialog>

							<div className='profile__headerBottomButtons'>
								<div className='headerBottom_follow_magazine'>
									{userId === user.uid ? (
										<Button
											className='magazineButton'
											variant='contained'
											startIcon={<ImportContactsIcon />}
											onClick={() =>
												history.push('/magazine')
											}
										>
											MANAGE MAGAZINES
										</Button>
									) : (
										<Button
											className='followButton'
											variant='contained'
											onClick={toggleFollow}
										>
											{isFollowing
												? 'Remove Connection'
												: 'Connect'}
										</Button>
									)}
								</div>
								<Button
									disabled={userId !== user.uid}
									variant='contained'
									startIcon={<CreateIcon />}
									onClick={() => history.push('/addNewPost')}
								>
									WRITE SOMETHING . . .
								</Button>
							</div>
						</div>

						<Dialog open={open} onClose={handleClose}>
							<div className='profileDialog'>
								<p onClick={() => history.push('/terms')}>
									Terms and Conditions
								</p>
								<p onClick={() => history.push('/support')}>
									Support
								</p>
								<p
									style={{ color: 'red' }}
									onClick={() => {
										localStorage.removeItem('user');
										history.push('/signin');
									}}
								>
									Logout
								</p>
							</div>
						</Dialog>
					</div>

					<div className='profile__nav'>
						<p style={{ color: '#1bad78' }}>Published Works</p>
						{userId === user.uid ? (
							<>
								<p onClick={() => history.push('/drafts')}>
									Drafts
								</p>
							</>
						) : null}
					</div>

					<div className='profile__body'>
						{isLoading ? (
							<>
								<CircularProgress color='secondary' />
								<p>Loading...</p>
							</>
						) : posts.length > 0 ? (
							posts.map((post) => (
								<PostCard
									key={post.id}
									title={post.title}
									color={post.backgroundColor}
									isNew={post.isNew}
									likes={post.likes.length}
									postId={post.id}
									isDraft={false}
									type={post.type}
								/>
							))
						) : (
							<p>No Posts Yet</p>
						)}
					</div>
				</div>
			)}
			<Navbar />
		</div>
	);
};

export default Profile;
