import React from 'react';
import Navbar from '../Navbar/Navbar';

import Logo from '../../images/penpoem-logo.png';

const About = () => {
	return (
		<div className='home-container'>
			<div className='home'>
				<div className='home-header'>
					<div className='logo'>
						<img src={Logo} alt='logo' />
						<p>penpoem</p>
					</div>
				</div>
				<h3>Coming soon... About Us</h3>
				<Navbar />
			</div>
		</div>
	);
};

export default About;
