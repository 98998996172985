import React from 'react';
import { useHistory } from 'react-router-dom';
import { Firebase } from '../../Firebase';
import './Signin.css';

import { FaGoogle } from 'react-icons/fa';
import Logo from '../../images/penpoem-logo.png';

import { useDispatch } from 'react-redux';
import { login } from '../../features/userSlice';

const Signin = () => {
	const dispatch = useDispatch();

	const db = Firebase.firestore();
	const history = useHistory();

	const handleLogin = (e) => {
		e.preventDefault();

		var provider = new Firebase.auth.GoogleAuthProvider();

		Firebase.auth()
			.signInWithPopup(provider)
			.then((result) => {
				var user = result.user;

				const userDetails = {
					imageUrl: user.photoURL,
					uid: user.uid,
					username: user.displayName,
				};

				localStorage.setItem('user', JSON.stringify(userDetails));
				// rohan edit
				dispatch(
					login({
						uid: user.uid,
						imageUrl: user.photoURL,
						email: user.email,
						username: user.displayName,
					})
				);

				db.doc(`/users/${user.uid}`)
					.get()
					.then((doc) => {
						if (doc.exists) {
							history.push('/');
						} else {
							const userData = {
								username: user.displayName,
								imageUrl: user.photoURL,
								email: user.email,
								followers: [],
								following: [],
							};
							db.doc(`/users/${user.uid}`).set(userData);
							history.push('/');
						}
					});
			})
			.catch((err) => {
				localStorage.clear();
			});
	};

	return (
		<div className='signin-container'>
			<div className='main'>
				<div className='header-img'></div>
				<div className='title'>
					<div className='signin-logo'>
						<img
							src={Logo}
							style={{
								objectFit: 'cover',
								width: '40px',
								height: '40px',
							}}
							alt='logo'
						/>
						<h3>penpoem</h3>
					</div>
					<button className='signin-btn' onClick={handleLogin}>
						<FaGoogle />
						Continue with Google
					</button>
				</div>
			</div>
			<div className='signin-footer'></div>
		</div>
	);
};

export default Signin;
