import React, { useState } from 'react';
import { Firebase } from '../../Firebase';
import { useHistory } from 'react-router-dom';

import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { Editor } from '@tinymce/tinymce-react';

const colors = [
	'#e74c3c',
	'#2ecc71',
	'#3498db',
	'#95a5a6',
	'#9b59b6',
	'#e67e22',
];

const RTE = ({ title, setIsLoading }) => {
	const history = useHistory();
	const db = Firebase.firestore();
	const user = JSON.parse(localStorage.getItem('user'));

	const [editorState, setEditorState] = useState(null);
	const [backgroundColor, setBackgroundColor] = useState('');

	const [open, setOpen] = useState(false);
	const [draft, setDraft] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const validate = () => {
		if (title.trim() === '' || backgroundColor === '') {
			return false;
		}
		return true;
	};

	const handleEditorChange = (content, state) => {
		setEditorState(content);
	};

	const handlePublish = async (e) => {
		e.preventDefault();
		if (!validate()) {
			return;
		}

		setIsLoading(true);

		let post = {
			timestamp: JSON.stringify(new Date()),
			title: title,
			body: JSON.stringify(editorState),
			uid: user.uid,
			username: user.username,
			userImage: user.imageUrl,
			backgroundColor: backgroundColor,
			type: 'post',
			likes: [],
			comments: [],
		};

		db.collection('/posts')
			.add(post)
			.then((_) => {
				setIsLoading(false);
				history.replace('/');
			});
	};

	const handleDraftPublish = (e) => {
		e.preventDefault();
		if (!validate()) {
			return;
		}

		setIsLoading(true);

		let post = {
			timestamp: JSON.stringify(new Date()),
			title: title,
			body: JSON.stringify(editorState),
			uid: user.uid,
			username: user.username,
			userImage: user.imageUrl,
			backgroundColor: backgroundColor,
			type: 'post',
			likes: [],
			comments: [],
		};

		db.collection('/drafts')
			.add(post)
			.then((_) => {
				setDraft(false);
				setIsLoading(false);
				history.replace('/');
			});
	};

	return (
		<div>
			<Editor
				apiKey='6tgyya78fo06suul7uu1qjioqgb7vks1n6pldux1uguklmv8'
				initialValue='<p>Start typing...</p>'
				init={{
					height: 600,
					menubar: false,
					plugins: [
						'advlist autolink lists link image charmap print preview anchor',
						'searchreplace visualblocks code fullscreen',
						'insertdatetime media table paste code help wordcount',
					],
					toolbar:
						'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
				}}
				onEditorChange={handleEditorChange}
			/>

			<div className='newPost__footer'>
				<Button
					variant='contained'
					startIcon={<SaveIcon />}
					onClick={() => {
						setDraft(true);
						handleClickOpen();
					}}
				>
					Save In Draft
				</Button>

				<Button
					variant='contained'
					startIcon={<PublishIcon />}
					onClick={handleClickOpen}
				>
					Publish
				</Button>

				<Dialog open={open} onClose={handleClose}>
					<div className='dialogBoxColor'>
						<h2>Select Cover Color</h2>
						<div className='coverColors'>
							{colors.map((color, index) => (
								<div
									key={index}
									className='coverColor'
									style={{
										backgroundColor: `${color}`,
										borderRadius: '50%',
										width: '50px',
										height: '50px',
									}}
									onClick={() => setBackgroundColor(color)}
								></div>
							))}
						</div>
						<p
							style={{
								fontWeight: 'bold',
								color: `${backgroundColor}`,
							}}
						>
							{backgroundColor !== ''
								? `${backgroundColor} Selected`
								: 'No Color Selected'}
						</p>
						<Button
							variant='contained'
							color='primary'
							startIcon={<PublishIcon />}
							onClick={draft ? handleDraftPublish : handlePublish}
						>
							{draft ? 'Save Draft' : 'Publish'}
						</Button>
					</div>
				</Dialog>
			</div>
		</div>
	);
};

export default RTE;
